import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Translation, useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Button, ButtonType, Icon, Tooltip, Popover } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { COLORS } from 'COLORS'
import { COMPARISON_TYPES } from 'constants/calendar'

import { momentDateObjectType } from 'types/general'

import { setLocalData } from 'helpers/storage'
import { computePreviousPeriodRange } from 'helpers/market_insights/share_and_trends'

import errorIcon from 'icons/svg/error-balloon.svg'
import { Chevron } from 'icons/Chevron/Chevron'

import { RadioButton } from 'ui_elements/RadioButton/RadioButton'
import { CompareDatesSection } from 'ui_elements/CalendarModal/CompareDatesSection'
import { MultiSelect } from 'ui_elements/MultiSelect/MultiSelect'

import { DateRangePicker } from './DateRangePicker/DateRangePicker'
import { getErrors } from './errors'

export const DATE_FORMAT = 'MMM DD, YYYY'

export const LEGACY_COMPARISON_TYPES = {
  previousYear: 'previous_year'
}

export const comparisonOptions = [
  {
    value: COMPARISON_TYPES.previousPeriod,
    label: (
      <Translation>
        {t => t('common:CalendarSelect.previousPeriod', 'Period')}
      </Translation>
    )
  },
  {
    value: COMPARISON_TYPES.previousYear,
    label: (
      <Translation>
        {t => t('common:CalendarSelect.previousYear', 'Year')}
      </Translation>
    )
  }
]

const defaultComparisonType = COMPARISON_TYPES.previousPeriod

const StyledMultiSelect = styled(MultiSelect)`
  .trigger {
    padding: 0 10px;
    .title {
      font-size: 12px !important;
      color: ${props => props.theme.colors.grey900} !important;
    }
  }
  width: auto !important;
  margin: 3px 0;

  .option {
    .display-name {
      margin-left: 0 !important;
      ${TEXT_STYLES.BodyBlack}
    }
  }

  .option.selected .display-name {
    color: ${props => props.theme.colors.primary} !important;
  }

  ${props =>
    props.highlightedIndex > -1 &&
    css`
      .option:nth-child(${props.highlightedIndex + 1}) {
        background-color: ${props.theme.colors.grey050};
      }
    `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.previousStartDate && props.previousEndDate
      ? 'space-evenly'
      : 'center'};
  height: 30px;
  caret-color: transparent;
`

const Title = styled.strong`
  ${props =>
    props.singleTitleHeader ? TEXT_STYLES.H4Black : TEXT_STYLES.H5Black}
  line-height: 1;
`

const SubTitle = styled.span`
  ${TEXT_STYLES.H5Black}
  color: ${props => props.theme.colors.grey600};
  line-height: 1;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Footer = styled.footer`
  padding: 10px 12px 5px 12px;
  display: flex;
  justify-content: ${props =>
    props.showComparisonTypeChange ? 'space-between' : 'flex-end'};
`

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-bottom: 8px;
`

const InputLabel = styled.label`
  ${TEXT_STYLES.H4Black}
`

const ErrorMessage = styled.div`
  display: flex;
  ${TEXT_STYLES.BodyRedError}
  align-items: center;
  line-height: 1;
  margin: 8px 10px 0;
`

const ErrorImg = styled.img`
  margin-right: 8px;
`

const ComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    ${TEXT_STYLES.H5Inactive}
  }
`

const ComparisonRadiosContainer = styled.div`
  display: flex;
  cursor: default;
`

const ComparisonRadio = styled.div`
  display: flex;
  & + & {
    margin-left: 12px;
  }
`

const Label = styled.label`
  margin-left: 5px;
  margin-top: 1px !important;
  ${TEXT_STYLES.H4Black};
  ${props => (props.disabled ? `color: ${COLORS.grey400}` : '')}
`

const PresetRangeWrapper = styled.div`
  padding: 3px 12px;
`

const DateRangePickerWrapper = styled.div`
  header {
    padding-left: 12px;
    padding-right: 12px;
  }
`

const TitleDropdown = styled.div`
  font-size: 12px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey100};
  border-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 360px;
  margin-left: 16px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`

const StyledChevron = styled(Chevron)`
  transform: ${props => (props.isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
  width: 7px;
  height: 7px;
  path {
    fill: ${props => props.theme.colors.grey900};
  }
`

const StyledCompareDatesDiv = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  .trigger {
    padding: 0 10px;
    .title {
      color: ${props => props.theme.colors.grey900} !important;
    }
  }
`

const Divider = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grey100};
  margin-top: 12px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const getSelectItem = (ranges, startDate, endDate) => {
  return ranges.find(
    range =>
      range.startDate.isSame(startDate, 'date') &&
      range.endDate.isSame(endDate, 'date')
  )
}

const getDirtyItemIndex = (ranges, dirtyStartDate, dirtyEndDate) => {
  const item = getSelectItem(ranges, dirtyStartDate, dirtyEndDate)

  return ranges.indexOf(item)
}

const customItemTitle = (
  <Translation>
    {t => t('common:CalendarSelect.customItem', 'Custom')}
  </Translation>
)

const renderDefaultSelectedDateRangeTitle = (
  dateRangeType,
  startDate,
  endDate,
  singleTitleHeader,
  t
) => {
  if (!startDate || !endDate) {
    return (
      <Title singleTitleHeader>
        {t('common:CalendarSelect.labels.selectDateRange', 'Select date range')}
      </Title>
    )
  }

  const formattedDateRange = isMobile
    ? ''
    : `(${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)})`

  return (
    <Title singleTitleHeader={singleTitleHeader}>
      {dateRangeType} {formattedDateRange}
    </Title>
  )
}

const renderComparison = (previousStartDate, previousEndDate) => {
  const label = `${previousStartDate.format(
    DATE_FORMAT
  )} - ${previousEndDate.format(DATE_FORMAT)}`
  return (
    <SubTitle>
      <Translation>
        {t =>
          t('common:CalendarSelect.comparison', 'Comparing to: {{label}}', {
            label
          })
        }
      </Translation>
    </SubTitle>
  )
}

const renderTitle = opts => {
  const {
    dateRangeType,
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    singleTitleHeader,
    renderSelectedDateRangeTitle,
    dateRanges,
    t
  } = opts

  const title =
    renderSelectedDateRangeTitle?.({
      dateRangeType,
      startDate,
      endDate,
      dateRanges
    }) ||
    renderDefaultSelectedDateRangeTitle(
      dateRangeType,
      startDate,
      endDate,
      singleTitleHeader,
      t
    )

  return (
    <TitleWrapper
      previousStartDate={previousStartDate}
      previousEndDate={previousEndDate}>
      {title}
      {previousStartDate &&
        previousEndDate &&
        renderComparison(previousStartDate, previousEndDate)}
    </TitleWrapper>
  )
}

const renderComparisonOptions = (
  comparisonType,
  onComparisonTypeChange,
  compareSectionEnabled,
  compareSectionDisabledMessage
) => {
  const inner = (
    <ComparisonRadiosContainer>
      {comparisonOptions.map(option => (
        <ComparisonRadio key={option.value}>
          <RadioButton
            ariaLabelledby={option.value}
            onClick={() => onComparisonTypeChange(option.value)}
            isSelected={comparisonType === option.value}
            disabled={!compareSectionEnabled}
          />
          <Label
            id={option.value}
            disabled={!compareSectionEnabled}
            onClick={() =>
              compareSectionEnabled && onComparisonTypeChange(option.value)
            }>
            {option.label}
          </Label>
        </ComparisonRadio>
      ))}
    </ComparisonRadiosContainer>
  )

  if (compareSectionEnabled) {
    return inner
  }

  return (
    <Tooltip side="left" content={compareSectionDisabledMessage} size="medium">
      {inner}
    </Tooltip>
  )
}

export const CalendarSelect = ({
  className,
  dateRanges,
  startDate,
  endDate,
  onDatesChange,
  onPreviousDatesChange,
  minDate,
  maxDate,
  numberOfMonths,
  initialVisibleMonth,
  previousStartDate,
  previousEndDate,
  comparisonType,
  onComparisonTypeChange,
  setShareAndTrendsPreviousPeriodDates,
  onDateRangeChange,
  dateRangeType,
  compareSectionEnabled,
  compareSectionDisabledMessage,
  singleTitleHeader,
  customizedEndDateErrorsMessage,
  dateRangeFormatter,
  applyButtonDataId,
  showComparisonPeriodDropdown,
  onSubmit,
  renderFooterDescription,
  renderDropdownTitle,
  renderSelectedDateRangeTitle,
  localStorageKey
}) => {
  const { t } = useTranslation()
  const multiSelectRef = useRef()
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [dirtyStartDate, setDirtyStartDate] = useState(startDate)
  const [dirtyEndDate, setDirtyEndDate] = useState(endDate)
  const [dirtyComparisonType, setDirtyComparisonType] = useState(comparisonType)
  const [dirtyDateRange, setDirtyDateRange] = useState(dateRangeType)
  const [cleanDateRange, setCleanDateRange] = useState(dateRangeType)

  const [showCompareDateSelect, setShowCompareDateSelect] = useState(false)
  const [compareDateRangeSelectOpen, setCompareDateRangeSelectOpen] = useState(
    false
  )
  const [
    shouldCompareToPeriodChanged,
    setShouldCompareToPeriodChanged
  ] = useState(false)
  const [dirtyComparisonPeriod, setDirtyComparisonPeriod] = useState(
    'previous_period'
  )
  const selectedItem = getSelectItem(dateRanges, startDate, endDate)

  const dirtyItemIndex = getDirtyItemIndex(
    dateRanges,
    dirtyStartDate || startDate,
    dirtyEndDate || endDate
  )

  useEffect(() => {
    const rangeType =
      dirtyItemIndex === -1
        ? customItemTitle
        : dateRanges[dirtyItemIndex].rangeType
    setDirtyDateRange(rangeType)
  }, [
    dirtyDateRange,
    dateRanges,
    startDate,
    endDate,
    renderDropdownTitle,
    dirtyItemIndex
  ])

  const { startDateError, endDateError } = getErrors(t, {
    dateFormat: DATE_FORMAT,
    minDate,
    maxDate,
    startDate: dirtyStartDate,
    endDate: dirtyEndDate,
    customizedEndDateErrorsMessage
  })

  const error = startDateError || endDateError
  const datesNotUpdated =
    dirtyStartDate === startDate && dirtyEndDate === endDate
  const noInputChanged =
    datesNotUpdated &&
    dirtyComparisonType === comparisonType &&
    !shouldCompareToPeriodChanged

  const isSubmitDisabled =
    noInputChanged || !!error || (!dirtyStartDate || !dirtyEndDate)

  const newDates = () => {
    if (datesNotUpdated) {
      return { startDate, endDate }
    }
    return { startDate: dirtyStartDate, endDate: dirtyEndDate }
  }

  const handleSelection = selection => {
    const item = getSelectItem(
      dateRanges,
      selection.startDate,
      selection.endDate
    )
    if (item) {
      setDirtyStartDate(item.startDate)
      setDirtyEndDate(item.endDate)
    } else {
      setDirtyStartDate(maxDate?.clone() || moment())
      setDirtyEndDate(maxDate?.clone() || moment())
    }
    setDirtyDateRange(selection.rangeType)
    setIsSelectOpen(false)
  }

  const close = () => {
    setDirtyStartDate(startDate)
    setDirtyEndDate(endDate)
    setDirtyComparisonType(comparisonType)
    setIsSelectOpen(false)
    setIsCalendarOpen(false)
    setShouldCompareToPeriodChanged(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isSubmitDisabled) {
      return
    }

    let dateRange = null
    if (onDateRangeChange) {
      // This is to account for custom selection via calendar or input changes.
      dateRange = dirtyItemIndex === -1 ? 'Custom' : dirtyDateRange
      onDateRangeChange(dateRange)
      setCleanDateRange(dateRange)
    }

    let dates = newDates()
    if (dateRangeFormatter) {
      dates = dateRangeFormatter(dates)
    }

    const { startDate: formStart, endDate: formEnd } = newDates()

    const {
      previousStartDate: prevStartDate,
      previousEndDate: prevEndDate
    } = computePreviousPeriodRange(
      formStart,
      formEnd,
      dirtyComparisonType,
      dirtyDateRange
    )

    if (setShareAndTrendsPreviousPeriodDates) {
      setShareAndTrendsPreviousPeriodDates({
        previousStartDate: prevStartDate,
        previousEndDate: dates.startDate.isBefore(prevEndDate)
          ? dates.startDate
              .clone()
              .utc()
              .subtract(1, 'millisecond')
          : prevEndDate
      })
    }

    if (onPreviousDatesChange) {
      onPreviousDatesChange(prevStartDate, prevEndDate)
    }

    onDatesChange({ ...dates, dateRange })

    if (onComparisonTypeChange) {
      onComparisonTypeChange(dirtyComparisonType)
    }

    if (localStorageKey) {
      setLocalData(
        localStorageKey,
        JSON.stringify({
          ...dates,
          previousStartDate: prevStartDate,
          previousEndDate: prevEndDate,
          dateRange,
          comparisonType: dirtyComparisonType
        })
      )
    }

    setIsCalendarOpen(false)
    setShouldCompareToPeriodChanged(false)

    if (onSubmit) {
      onSubmit({
        showCompareDateSelect,
        dirtyComparisonPeriod,
        dirtyStartDate,
        dirtyEndDate
      })
    }
  }

  const dropdownTitle =
    dirtyItemIndex === -1
      ? customItemTitle
      : renderDropdownTitle?.({
          dateRangeType: dirtyDateRange,
          dateRanges
        }) || dirtyDateRange

  return (
    <Popover
      width="100%"
      open={isCalendarOpen}
      onOpenChange={setIsCalendarOpen}
      modal
      content={
        <form onSubmit={handleSubmit}>
          <PresetRangeWrapper>
            <InputLabel>
              {t('common:CalendarSelect.labels.dateRange', 'Date Range')}
            </InputLabel>
            <StyledMultiSelect
              highlightedIndex={dirtyItemIndex}
              className={className}
              multiSelectRef={multiSelectRef}
              valueProp="name"
              disableSelectAll
              hideSelector
              elements={dateRanges}
              title={dropdownTitle}
              forceSingleSelection={false}
              selectedElements={[selectedItem]}
              isComponentOpen={isSelectOpen}
              handleToggle={() => {
                setIsSelectOpen(wasOpen => {
                  if (!wasOpen) {
                    setDirtyComparisonType(comparisonType)
                  }

                  return !wasOpen
                })
              }}
              handleOutsideClick={() => setIsSelectOpen(false)}
              handleSelection={handleSelection}
            />
          </PresetRangeWrapper>

          <DateRangePickerWrapper>
            <DateRangePicker
              startDate={dirtyStartDate}
              endDate={dirtyEndDate}
              onStartDateChange={setDirtyStartDate}
              onEndDateChange={setDirtyEndDate}
              startDateError={startDateError}
              endDateError={endDateError}
              onCancel={close}
              onDatesChange={onDatesChange}
              minDate={minDate}
              maxDate={maxDate}
              numberOfMonths={numberOfMonths}
              initialVisibleMonth={initialVisibleMonth}
            />
          </DateRangePickerWrapper>

          <Divider />

          {showComparisonPeriodDropdown && (
            <StyledCompareDatesDiv>
              <CompareDatesSection
                t={t}
                allowCustomPeriodSelection
                showCompareDateSelect={showCompareDateSelect}
                setShowCompareDateSelect={value => {
                  setShowCompareDateSelect(value)
                  setShouldCompareToPeriodChanged(true)
                }}
                compareDateRangeSelectOpen={compareDateRangeSelectOpen}
                setCompareDateRangeSelectOpen={value =>
                  setCompareDateRangeSelectOpen(value)
                }
                compareDateRangeSelectValue={dirtyComparisonPeriod}
                setCompareDateRangeSelectValue={value => {
                  setDirtyComparisonPeriod(value)
                  setShouldCompareToPeriodChanged(true)
                }}
              />
            </StyledCompareDatesDiv>
          )}

          <FooterContainer>
            {error && (
              <ErrorMessage>
                <ErrorImg
                  src={errorIcon}
                  alt={t('common:DatePicker.error.label', 'Error')}
                />
                {error}
              </ErrorMessage>
            )}
            {renderFooterDescription?.({
              startDate,
              endDate,
              minDate,
              maxDate,
              error,
              t
            })}
            <Footer showComparisonTypeChange={onComparisonTypeChange}>
              {onComparisonTypeChange && (
                <ComparisonContainer>
                  <span>
                    {t(
                      'common:CalendarSelect.comparsionTitle',
                      'Compare to previous:'
                    )}
                  </span>
                  {renderComparisonOptions(
                    dirtyComparisonType ||
                      comparisonType ||
                      defaultComparisonType,
                    setDirtyComparisonType,
                    compareSectionEnabled,
                    compareSectionDisabledMessage
                  )}
                </ComparisonContainer>
              )}

              <FooterActions>
                <Button
                  type="reset"
                  btnType={ButtonType.TERTIARY}
                  width="85px"
                  onClick={close}>
                  {t('common:DatePicker.cancel', 'Cancel')}
                </Button>
                <Button
                  type="submit"
                  btnType={ButtonType.PRIMARY}
                  width="85px"
                  disabled={isSubmitDisabled}
                  dataId={applyButtonDataId}
                  data-testid="calender-submit-button">
                  {t('common:DatePicker.apply', 'Apply')}
                </Button>
              </FooterActions>
            </Footer>
          </FooterContainer>
        </form>
      }>
      <TitleDropdown data-testid="calendar-select">
        <IconContainer>
          <Icon name="CALENDAR" />
          {renderTitle({
            dateRangeType: cleanDateRange,
            startDate,
            endDate,
            previousStartDate,
            previousEndDate,
            singleTitleHeader,
            renderSelectedDateRangeTitle,
            dateRanges,
            t
          })}
        </IconContainer>
        <StyledChevron isOpen={isCalendarOpen} />
      </TitleDropdown>
    </Popover>
  )
}

CalendarSelect.defaultProps = {
  className: undefined,
  startDate: undefined,
  endDate: undefined,
  initialVisibleMonth: undefined,
  numberOfMonths: 25,
  previousStartDate: undefined,
  previousEndDate: undefined,
  comparisonType: defaultComparisonType,
  onDateRangeChange: undefined,
  dateRangeType: '1 Year',
  compareSectionEnabled: true,
  compareSectionDisabledMessage: '',
  customizedEndDateErrorsMessage: '',
  onComparisonTypeChange: undefined,
  setShareAndTrendsPreviousPeriodDates: undefined,
  singleTitleHeader: false,
  dateRangeFormatter: undefined,
  applyButtonDataId: 'calender-submit-button',
  showComparisonPeriodDropdown: false,
  onSubmit: undefined,
  onPreviousDatesChange: undefined,
  renderFooterDescription: undefined,
  renderDropdownTitle: undefined,
  renderSelectedDateRangeTitle: undefined,
  localStorageKey: undefined
}

const dateRangeType = PropTypes.shape({
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  startDate: momentDateObjectType.isRequired,
  endDate: momentDateObjectType.isRequired
})

export const dateRangesType = PropTypes.arrayOf(dateRangeType)

CalendarSelect.propTypes = {
  className: PropTypes.string,
  dateRanges: dateRangesType.isRequired,
  startDate: momentDateObjectType,
  endDate: momentDateObjectType,
  onDatesChange: PropTypes.func.isRequired,
  onPreviousDatesChange: PropTypes.func,
  minDate: momentDateObjectType.isRequired,
  maxDate: momentDateObjectType.isRequired,
  initialVisibleMonth: PropTypes.func,
  numberOfMonths: PropTypes.number,
  previousStartDate: PropTypes.oneOfType([
    PropTypes.bool,
    momentDateObjectType
  ]),
  previousEndDate: PropTypes.oneOfType([PropTypes.bool, momentDateObjectType]),
  comparisonType: PropTypes.string,
  onComparisonTypeChange: PropTypes.func,
  setShareAndTrendsPreviousPeriodDates: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  dateRangeType: PropTypes.string,
  compareSectionEnabled: PropTypes.bool,
  compareSectionDisabledMessage: PropTypes.string,
  customizedEndDateErrorsMessage: PropTypes.string,
  singleTitleHeader: PropTypes.bool,
  dateRangeFormatter: PropTypes.func,
  applyButtonDataId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  showComparisonPeriodDropdown: PropTypes.bool,
  onSubmit: PropTypes.func,
  renderFooterDescription: PropTypes.func,
  renderDropdownTitle: PropTypes.func,
  renderSelectedDateRangeTitle: PropTypes.func,
  localStorageKey: PropTypes.string
}
