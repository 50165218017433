import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TEXT_STYLES } from 'constants/text_styles'

const BetaLabel = styled.div`
  ${TEXT_STYLES.H5Inactive}
  font-weight: 600 !important;
  margin: ${props => props.margin};
  background-color: ${props => props.theme.colors.grey100};
  padding: 0px 4px;
  border-radius: 15px;
`

export const BetaTag = ({ margin }) => {
  const { t } = useTranslation(['generic'])

  return <BetaLabel margin={margin}>{t('generic:BetaTag', 'BETA')}</BetaLabel>
}

BetaTag.defaultProps = {
  margin: '0 0 0 12px'
}

BetaTag.propTypes = {
  margin: PropTypes.string
}
