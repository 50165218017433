import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MultiSelect } from 'ui_elements/MultiSelect/MultiSelect'
import { CheckBoxGroup } from 'ui_elements/CheckBoxGroup/CheckBoxGroup'

const CompareDatesDiv = styled.div`
  margin-top: 15px;
  caret-color: transparent;
`

const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
  ${props => {
    if (props.disabled) {
      return `
        div[class*='trigger'] {
          background-color: ${props.theme.colors.white};
        }
        div[class*='title'] {
          color: ${props.theme.colors.grey600};
        }
      `
    }
  }}
`

export function CompareDatesSection({
  dataIds,
  allowCustomPeriodSelection,
  showCompareDateSelect,
  setShowCompareDateSelect,
  compareDateRangeSelectValue,
  setCompareDateRangeSelectValue
}) {
  const { t } = useTranslation()

  const elements = [
    {
      name: t(
        'common:CalendarModal.previousPeriodsLabel',
        `Previous Period${allowCustomPeriodSelection ? '(s)' : ''}`
      ),
      value: 'previous_period'
    },
    {
      name: t('common:CalendarModal.previousYearLabel', 'Previous Year'),
      value: 'previous_year'
    }
  ]

  const selectedElement = elements.find(
    item => item.value === compareDateRangeSelectValue
  )
  const selectTitle = selectedElement ? selectedElement.name : ''

  // Pendo tag
  const compareDatesCheckboxId = dataIds ? dataIds.compareDatesCheckboxId : ''

  const [compareDateRangeSelectOpen, setCompareDateRangeSelectOpen] = useState(
    false
  )

  return (
    <CompareDatesDiv>
      <CheckBoxGroup
        label={t(
          'common:CalendarModal.compareLabel',
          'Compare to previous dates'
        )}
        checked={showCompareDateSelect}
        dataId={compareDatesCheckboxId}
        handleOnClick={() => {
          if (showCompareDateSelect) {
            setCompareDateRangeSelectValue(null)
          }
          setShowCompareDateSelect(!showCompareDateSelect)
        }}
      />
      {showCompareDateSelect && (
        <StyledMultiSelect
          elements={elements}
          title={selectTitle}
          selectedElements={[compareDateRangeSelectValue]}
          isComponentOpen={compareDateRangeSelectOpen}
          handleToggle={() =>
            setCompareDateRangeSelectOpen(!compareDateRangeSelectOpen)
          }
          handleSelection={e => {
            setCompareDateRangeSelectValue(e.value)
            setCompareDateRangeSelectOpen(false)
          }}
          handleOutsideClick={() => setCompareDateRangeSelectOpen(false)}
          optionsHeight="150px"
          disableSelectAll
          forceSingleSelection
          hideSelector
          width="100%"
        />
      )}
    </CompareDatesDiv>
  )
}

CompareDatesSection.defaultProps = {
  compareDateRangeSelectValue: '',
  allowCustomPeriodSelection: false,
  showCompareDateSelect: false, // If the comparison checkbox is checked
  dataIds: undefined
}

CompareDatesSection.propTypes = {
  dataIds: PropTypes.shape({
    applyButtonId: PropTypes.string, // Pendo Tag Id
    cancelButtonId: PropTypes.string, // Pendo Tag Id
    dateRangeDropdownId: PropTypes.string, // Pendo Tag Id
    compareDatesCheckboxId: PropTypes.string // Pendo Id Tag
  }),
  allowCustomPeriodSelection: PropTypes.bool, // If we should show the user the `Number of Periods` option
  showCompareDateSelect: PropTypes.bool, // If the comparison checkbox is checked
  setShowCompareDateSelect: PropTypes.func.isRequired,
  compareDateRangeSelectValue: PropTypes.string,
  setCompareDateRangeSelectValue: PropTypes.func.isRequired
}
